import React, { Component } from "react"
import { graphql } from "gatsby"
import memoizeOne from "memoize-one"
import PageTransition from "gatsby-plugin-page-transitions"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockContent from "@sanity/block-content-to-react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion"

const serializers = {
  types: {
    youtube: props =>
      props.type === "youtube" ? null : (
        <div
          style={{
            marginLeft: "2.0001rem",
            marginRight: "2rem",
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
          }}
        >
          <iframe
            src={props.node.url}
            title={props.node.url}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              border: 0,
            }}
            seamless
          />
        </div>
      ),
    bandcamp: props =>
      props.type === "bandcamp" ? null : (
        <div
          style={{
            marginLeft: "2rem",
            marginRight: "2rem",
          }}
        >
          <iframe
            src={props.node.url}
            title={props.node.url}
            style={{
              width: "100%",
              height: "120px",
              border: 0,
            }}
            seamless
          />
        </div>
      ),
    vimeo: props =>
      props.type === "vimeo" ? null : (
        <div
          style={{
            marginLeft: "2rem",
            marginRight: "2rem",
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
          }}
        >
          <iframe
            src={props.node.url}
            title={props.node.url}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              border: 0,
            }}
            seamless
          />
        </div>
      ),
    soundcloud: props =>
      props.type === "soundcloud" ? null : (
        <div
          style={{
            marginLeft: "2rem",
            marginRight: "2rem",
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
          }}
        >
          <iframe
            src={props.node.url}
            title={props.node.url}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              border: 0,
            }}
            seamless
          />
        </div>
      ),
  },
}

function getTagList(edges) {
  return Array.from(
    new Set(edges.reduce((arr, project) => arr.concat(project.node.tags), []))
  )
}

export default class Projects extends Component {
  constructor(props) {
    super(props)
    this.getTagList = memoizeOne(getTagList)
    this.state = {
      filterTags: [],
      isReversed: false,
    }
  }

  filterTags = tag => {
    this.setState(state => {
      return {
        filterTags: state.filterTags.includes(tag)
          ? state.filterTags.filter(t => t !== tag)
          : state.filterTags.concat(tag),
      }
    })
  }

  reverseArray() {
    this.setState({
      isReversed: !this.state.isReversed,
    })
  }

  render() {
    const { data } = this.props
    const tagList = this.getTagList(data.allSanityProjects.edges)
    const allSanityProjects = this.props.data.allSanityProjects
    const edges =
      this.state.filterTags.length === 0
        ? allSanityProjects.edges
        : allSanityProjects.edges.filter(edge =>
            this.state.filterTags.every(tag => edge.node.tags.includes(tag))
          )
    const mapProjects = edges.map(({ node: projects }) => (
      <div id="projects" key={projects.slug.current}>
        <Accordion>
          <AccordionItem>
            <div id="projects__text">
              <AccordionItemTitle>
                <div className="title__box">
                  <div className="title">{projects.title}</div>
                  <div className="year">{projects.year}</div>
                </div>
                <h4 className="subtitle">{projects.subtitle}</h4>
              </AccordionItemTitle>
              <AccordionItemBody
                className="accordionBody"
                hideBodyClassName="accordionBodyHidden"
              >
                <div className="tag__box">
                  {projects.tags.length > 0 &&
                    projects.tags.map(tag => {
                      return (
                        <span className="tag" key={tag}>
                          {tag}
                        </span>
                      )
                    })}
                </div>
                <BlockContent
                  projectId="r3xca30t"
                  dataset="production"
                  imageOptions={{ q: 100 }}
                  blocks={projects._rawContent}
                  serializers={serializers}
                />
              </AccordionItemBody>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    ))
    return (
      <PageTransition
        defaultStyle={{
          transition: `opacity 250ms ease-in-out`,
          opacity: 0,
        }}
        transitionStyles={{
          entering: { opacity: 0 },
          entered: { opacity: 1 },
        }}
        transitionTime={100}
      >
        <Layout location={this.props.location}>
          <SEO title="Projects" />

          <div id="projects__sortby">
            <div className="subtitle">SORT BY TAG:</div>
            <div className="tag__box">
              {tagList.map(tag => (
                <span
                  className={
                    this.state.filterTags.includes(tag)
                      ? "tag tag__active"
                      : "tag"
                  }
                  key={tag}
                  onClick={() => this.filterTags(tag)}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <div id="projects__box">{mapProjects}</div>
        </Layout>
      </PageTransition>
    )
  }
}

export const query = graphql`
  {
    allSanityProjects(sort: { fields: [year], order: [DESC] }) {
      edges {
        node {
          subtitle
          title
          year
          tags
          slug {
            current
          }
          _rawContent
        }
      }
    }
  }
`
